export default [
	{
		key: "name",
		label: "Nombre",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "value",
		label: "Valor",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "id",
		label: "Accion",
		visible: true,
		thClass: "text-center",
	},
	 
]
