import axios from "@/axios"

const url = "/api/brain/warehouse/products/weight-details"
class WeightService {

    async getWeightDetails(params) {
		try {
			const data = await axios.get(`${url}/index`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}
	async createWeightDetails(datas) {
		try {
			const data = await axios.post(`${url}/save`,datas)
			return data.data
		} catch (error) {
			throw error
		}
	}

	async deleteWeightDetail(id){
		try {
			const data = await axios.delete(`${url}/destroy/${id}`)
			return data.data
		} catch (error) {
			throw error
		}
	}


}
export default new WeightService()