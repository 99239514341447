export default [
	// {
	// 	key: "id",
	// 	label: "#",
	// 	visible: true,
	// 	thClass: "text-center",
	// },
	{
		key: "name",
		label: "Presentacion",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "description",
		label: "descripcion",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "presentations",
		label: "Unidad de medida",								
		visible: true,
		thClass: "text-center",
	},
	{
		key: "created_at",
		label: "Creado por",
		visible: true,
		thClass: "text-center",
	},
	{
		key: "actions",
		label: "Acciones",
		thClass: "text-center",
		visible: true,
	},
]
