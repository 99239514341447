<template>
	<div>
		<b-modal
			ref="modal-Create"
			@hidden="$emit('close')"
			title="Unidades de Medida"
			cancel-title="Cancelar"
			scrollable
			size="md"
			no-close-on-backdrop
			no-close-on-esc
		>
			<div>
				<b-form-group>
					<label for="presentation">Presentacion</label>
					<b-form-input
						id="presentation"
						type="text"
						v-model="idUnit.name"
						readonly
						placeholder="Ingrese el valor"
					/>
				</b-form-group>
				<ValidationObserver ref="formCategories">
					<ValidationProvider v-slot="{ errors }" rules="required">
						<b-form-group :state="errors[0] ? false : null">
							<div class="d-flex justify-content-between align-items-center">
								<label for="measurement_unit_id">Unid. medida específica</label>
								<feather-icon
									icon="PlusIcon"
									class="text-success cursor-pointer"
									size="19"
									@click="openModalCreateUnit = !openModalCreateUnit"
								/>
							</div>
							<v-select
								input-id="measurement_unit_id"
								:clearable="false"
								v-model="form.unitId"
								label="name"
								:options="dataSelect"
								:reduce="(cc) => cc.id"
								appendToBody
								:calculatePosition="positionDropdown"
								placeholder="Seleccione una presentación..."
							/>
						</b-form-group>
					</ValidationProvider>

					<ValidationProvider v-slot="{ errors }" rules="required">
						<b-form-group :state="errors[0] ? false : null">
							<label for="name">Valor</label>
							<b-form-input
								id="name"
								:state="errors[0] ? false : null"
								type="number"
								v-model="form.value"
								placeholder="Ingrese el valor"
							/>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>

				<div class="d-flex justify-content-end pb-1">
					<b-button variant="primary" @click="createDetailsWeight">Agregar</b-button>
				</div>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="fields"
					sticky-header="40vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-Weight"
				>
					<template #table-busy>
						<div class="text-center text-primary">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(name)="data">
						<div class="d-flex justify-content-center">
							<span>{{ data.item.name }}</span>
						</div>
					</template>
					<template #cell(value)="data">
						<div class="d-flex justify-content-center">
							<span>{{ data.item.value }}</span>
						</div>
					</template>
					<template #cell(id)="data">
						<div class="d-flex justify-content-center">
							<b-button variant="danger" class="btn-icon btn-sm" @click="deleteDetail(data.item)">
								<feather-icon icon="TrashIcon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			</template>
		</b-modal>

		<WeightUnitModal
			v-if="openModalCreateUnit"
			:info="{
				id: null,
				name: null,
				description: null,
			}"
			:type="`specific`"
			@registered="getSelectWeight(0), (openModalCreateUnit = false)"
			@close="openModalCreateUnit = false"
		/>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import Fields from "@/views/brain/administrative/views/weight-units/data/fields-details.js"
import serviceWeightDetails from "@/views/brain/administrative/views/weight-units/services/weightDetails.service.js"
import WeightUnitModal from "@/views/brain/administrative/views/weight-units/components/WeightUnitModal.vue"
import serviceWeight from "@/views/brain/administrative/views/weight-units/services/weight.service.js"
export default {
	props: {
		idUnit: {
			required: true,
		},
	},

	directives: { Ripple },
	mixins: [modalMixin],
	data() {
		return {
			fields: Fields,
			form: {
				unitId: null,
				value: null,
			},
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			current_page: 1,
			to_page: 0,
			dataSelect: [],
			openModalCreateUnit: false,
		}
	},
	components: {
		WeightUnitModal,
	},

	async mounted() {
		this.toggleModal("modal-Create")
	},

	async created() {
		this.isPreloading()
		try {
			await Promise.all([this.getSelectWeight()])
		} catch (error) {
			console.log(error)
		} finally {
			this.isPreloading(false)
		}
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				page: ctx.currentPage,
				perpage: ctx.perPage,
				measurement_unit_id: this.idUnit.id,
			}
			try {
				const { data } = await serviceWeightDetails.getWeightDetails(params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		async getSelectWeight(registered = 1) {
			if (registered == 0) this.isPreloading()

			try {
				const params = {
					type: "specific",
				}
				const { data } = await serviceWeight.getWeightSelect(params)
				this.dataSelect = data
			} catch (error) {
				throw error
			} finally {
				if (registered == 0) this.isPreloading(false)
			}
		},
		reset() {
			this.$refs["table-Weight"].refresh()
		},
		async createDetailsWeight() {
			const validate = await this.$refs.formCategories.validate()
			if (!validate) return

			const confirm = await this.showConfirmSwal({
				text: `Agregar unidad de medida`,
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()
			try {
				const datas = {
					measurement_unit_id: this.idUnit.id,
					packaging_id: this.form.unitId,
					value: this.form.value,
				}
				const created = await serviceWeightDetails.createWeightDetails(datas)
				this.showSuccessToast(created.message)
				this.$refs["table-Weight"].refresh()
				this.form.unitId = null
				this.form.value = null
				this.$refs.formCategories.reset()
				this.$emit("registered")
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},

		async deleteDetail(item) {
			const confirm = await this.showConfirmSwal({
				text: `Eliminar unidad de medida: ${item.name}-${item.value}`,
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()
			try {
				const created = await serviceWeightDetails.deleteWeightDetail(item.id)
				this.showSuccessToast(created.message)
				this.$refs["table-Weight"].refresh()
				this.$refs.formCategories.reset()
				this.$emit("registered")
			} catch (error) {
				console.log(error)
				throw error
			} finally {
				this.isPreloading(false)
			}
		},

		closeModal() {
			this.$emit("close")
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
	},
}
</script>

<style scoped>
.d-code {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}
</style>
