var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-Create",attrs:{"title":"Unidades de Medida","cancel-title":"Cancelar","scrollable":"","size":"md","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")])]},proxy:true}])},[_c('div',[_c('b-form-group',[_c('label',{attrs:{"for":"presentation"}},[_vm._v("Presentacion")]),_c('b-form-input',{attrs:{"id":"presentation","type":"text","readonly":"","placeholder":"Ingrese el valor"},model:{value:(_vm.idUnit.name),callback:function ($$v) {_vm.$set(_vm.idUnit, "name", $$v)},expression:"idUnit.name"}})],1),_c('ValidationObserver',{ref:"formCategories"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{attrs:{"for":"measurement_unit_id"}},[_vm._v("Unid. medida específica")]),_c('feather-icon',{staticClass:"text-success cursor-pointer",attrs:{"icon":"PlusIcon","size":"19"},on:{"click":function($event){_vm.openModalCreateUnit = !_vm.openModalCreateUnit}}})],1),_c('v-select',{attrs:{"input-id":"measurement_unit_id","clearable":false,"label":"name","options":_vm.dataSelect,"reduce":function (cc) { return cc.id; },"appendToBody":"","calculatePosition":_vm.positionDropdown,"placeholder":"Seleccione una presentación..."},model:{value:(_vm.form.unitId),callback:function ($$v) {_vm.$set(_vm.form, "unitId", $$v)},expression:"form.unitId"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Valor")]),_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"type":"number","placeholder":"Ingrese el valor"},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}})],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end pb-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createDetailsWeight}},[_vm._v("Agregar")])],1)],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{ref:"table-Weight",attrs:{"items":_vm.myProvider,"fields":_vm.fields,"sticky-header":"40vh","primary-key":"id","current-page":_vm.current_page,"per-page":_vm.perpage,"responsive":"","small":"","show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Cargando...")])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(_vm._s(data.item.name))])])]}},{key:"cell(value)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(_vm._s(data.item.value))])])]}},{key:"cell(id)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"btn-icon btn-sm",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteDetail(data.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]}}])})],1)]),(_vm.openModalCreateUnit)?_c('WeightUnitModal',{attrs:{"info":{
			id: null,
			name: null,
			description: null,
		},"type":"specific"},on:{"registered":function($event){_vm.getSelectWeight(0), (_vm.openModalCreateUnit = false)},"close":function($event){_vm.openModalCreateUnit = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }